.crop-editing-cont {
    margin-bottom: 15px;
}

.crop-editing-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 7px;
}

.poi-overlays-cont.video-overlay-cont {
    pointer-events: initial;
}

.poi-graph-cont {
    position: relative;
    height: 120px;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    overflow: hidden;
}

.poi-graph-line {
    width: 100%;
    height: 120px;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
}

.poi-graph-dot {
    position: absolute;
    width: 1px;
    height: 1px;
    margin-top: -1px;
    background-color: white;
}

.poi-graph-dot.yellow {
    background-color: var(--dark-yellow);
}

.poi-graph-dot.green {
    background-color: var(--button-green);
}

.poi-graph-dot.no-poi {
    background-color: rgb(100, 100, 100);
}

.graph-type-btns {
    padding-left: 5px;
    margin-bottom: 15px;
}

.graph-type-btn {
    display: flex;
    align-items: center;
    gap: 2px;
}
.graph-type-btn input[type="checkbox"] {
    position: unset;
}

.poi-graph-indicator {
    position: absolute;
    top: 0;
    height: 100%;
    width: 0.5px;
    background-color: white;
}

.loading-graph-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-weight: 600;
    color: var(--light-blue);
}

.keyboard-shortcuts-cont {
    margin-top: 12px;
    border: 1px solid orange;
}

.crop-editing-aspect {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
}

button.cropping-aspect-ratio {
    padding: 0;
    height: unset;
    border: none;
    font-size: 12px;
    margin-top: 3px;
}

button.cropping-aspect-ratio.active {
    color: var(--dark-yellow);
}

.crop-editing-btns {
    /* position: absolute;
    top: -32px;
    right: 0; */
    display: flex;
    gap: 5px;
}

button.crop-editing-edit-btn {
    padding: 3px 7px;
    height: unset;
    font-size: 11px;
}

button.crop-editing-edit-btn svg {
    margin-bottom: -1px;
    font-size: 12px;
}

.edit-poi-cont {
    position: relative;
}

.beta-version-info {
    font-size: 11px;
    font-weight: 600;
    color: rgb(150, 150, 150);
}

/* HOVER */
button.cropping-aspect-ratio:not([disabled]):hover {
    color: var(--dark-yellow);
    background-color: unset;
}